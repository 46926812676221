<template>
  <v-container
    style="height: calc(100vh - 60px); overflow-y: auto"
    fluid
    class="py-0"
  >
    <v-row>
      <v-col class="py-0" cols="12">
        <Search
          :params="params"
          @handle-search="getData(1)"
          @handle-reset="reset"
          :options="options"
        />
      </v-col>
      <v-col class="py-0" cols="12">
        <DataTable
          :form="form"
          :table-data="tableData"
          @handle-edit="showDialogForm('edit', $event)"
          @handle-create="showDialogForm('create')"
          @deleted="getData()"
          :pagination="pagination"
          @sorted="handleSort"
          @handle-export="exportData"
        />
      </v-col>
      <v-col cols="12" class="py-0">
        <Pagination
          :length="pagination.lastPage"
          :params="params"
          @handle-change-page="getData"
          @handle-change-per-page="getData(1)"
        />
      </v-col>
    </v-row>
    <DialogForm
      @created="getData(1)"
      @updated="getData"
      :options="options"
      :show-dialog.sync="showDialog"
      :editing="editing"
      :form="form"
    />
  </v-container>
</template>

<script>
import DataTable from "./components/DataTable";
import Search from "./components/Search";
import DialogForm from "./components/DialogForm";
import Pagination from "@/components/Pagination";
import { index } from "@/api/user";

import indexMixin from "@/mixins/crud/index";
export default {
  mixins: [indexMixin(index)],
  components: { DataTable, Search, DialogForm, Pagination },
  data() {
    return {
      showDialog: false,
      editing: false,
      defaultParams: {
        search: "",
        page: 1,
        perPage: 20,
        sortBy: "id"
      },
      form: {
        id: undefined,
        name: "",
        email: ""
      }
    };
  }
};
</script>
